export const mixin = {
    inheritAttrs: false,
    props: {
        label: {
            type: String,
            default: '',
        },
        value: [String, Number],
        username: {
            type: String,
        },
        phonenumber: {
            type: Number,
        },
    },
    methods: {
        updateValue(formdata) {
            this.$emit('input', formdata.target.value)
        },
    },
}